import request from '@/utils/request'
import qs from "qs";

export function queryQuestionType(data) {
    return request({
        url: 'questionType/queryQuestionType',
        method: 'get',
        params: data
    })
}

export function queryTypeDetails(id) {
    return request({
        url: 'questionType/queryTypeDetails',
        method: 'get',
        params: id
    })
}

export function insertQuestionType(data) {
    return request({
        url: 'questionType/insertQuestionType',
        method: 'post',
        data: data
    })
}

export function updateQuestionTypeById(data) {
    return request({
        url: 'questionType/updateQuestionTypeById',
        method: 'put',
        data: data
    })
}
export function deleteQuestionTypeById(questionTypeId) {
    return request({
        url: 'questionType/deleteQuestionTypeById',
        method: 'delete',
        data: qs.stringify(questionTypeId, { arrayFormat: 'comma' })
    })
}