<template>
  <div class="app-container menus">
    <el-form
      :model="queryParams"
      ref="queryParams"
      v-hasPermi="['stion:que:list']"
      :inline="true"
      v-show="showSearch"
      label-width="68px"
      style="width: 100%; height: 0.5%; text-align: left"
    >
      <el-form-item label="题库名称" prop="title">
        <el-input
          v-model="queryParams.title"
          placeholder="请输入题库名称"
          clearable
          size="small"
          style="width: 240px"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          icon="el-icon-search"
          size="mini"
          @click="handleQuery"
          v-hasPermi="['stion:que:list']"
          >搜索</el-button
        >
        <el-button
          icon="el-icon-refresh"
          size="mini"
          @click="resetQuery"
          v-hasPermi="['stion:que:list']"
          >重置</el-button
        >
      </el-form-item>
    </el-form>
    <el-row :gutter="10" class="mb8">
      <el-col :span="1.5">
        <el-button
          type="primary"
          plain
          icon="el-icon-plus"
          size="mini"
          @click="handleAdd()"
          v-hasPermi="['stion:que:add']"
          >新增</el-button
        >
      </el-col>
    </el-row>

    <el-table ref="tables" :data="list" v-loading="loading">
      <el-table-column label="题库编号" align="center" prop="id" width="100" />
      <el-table-column
        label="题库名称"
        align="center"
        prop="title"
        :show-overflow-tooltip="true"
      />
      <el-table-column
        label="排序"
        align="center"
        prop="displayorder"
        width="150"
      />
      <el-table-column label="题量" align="center" prop="count" width="150" />
      <el-table-column
        label="描述"
        align="center"
        prop="description"
        width="200"
        :show-overflow-tooltip="true"
      />
      <el-table-column label="状态" align="center" prop="status">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.status == 1">正常</el-tag>
          <el-tag v-if="scope.row.status == 0" type="danger">停用</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        align="center"
        class-name="small-padding fixed-width"
      >
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="text"
            icon="el-icon-edit"
            @click="handleUpdate(scope.row)"
            v-hasPermi="['stion:que:eqit']"
            >修改</el-button
          >
          <el-button
            v-if="scope.row.parentId !== 0"
            size="mini"
            type="text"
            icon="el-icon-delete"
            @click="handleDelete(scope.row)"
            v-hasPermi="['stion:que:delete']"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <!-- 添加或修改对话框 -->
    <el-dialog :title="title" :visible.sync="open" width="600px" append-to-body>
      <el-form
        ref="typeForm"
        :model="typeForm"
        :rules="rules"
        label-width="80px"
        style="width: 100%; height: 100%"
      >
        <el-form-item label="分类标题" prop="title">
          <el-input
            v-model="typeForm.title"
            placeholder="请输入分类标题"
            maxlength="20"
            autocomplete="off"
          />
        </el-form-item>
        <el-form-item label="显示排序" prop="displayorder">
          <el-input-number
            v-model="typeForm.displayorder"
            controls-position="right"
            :min="0"
            :max="1000"
            autocomplete="off"
            style="width: 100%"
          />
        </el-form-item>
        <el-form-item
          label="分类状态"
          label-width="80px"
          prop="status"
          autocomplete="off"
        >
          <el-radio-group v-model="typeForm.status">
            <el-radio :label="'1'">显示</el-radio>
            <el-radio :label="'0'">隐藏</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="描述" prop="description">
          <el-input
            type="textarea"
            v-model="typeForm.description"
            placeholder="请输入描述"
            maxlength="100"
            autocomplete="off"
            :rows="5"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  queryQuestionType,
  queryTypeDetails,
  insertQuestionType,
  updateQuestionTypeById,
  deleteQuestionTypeById,
} from "@/api/exam/questionType";
export default {
  name: "QuestionType",
  data() {
    return {
      //遮盖层
      loading: false,
      // 搜索
      showSearch: true,
      // 添加修改
      open: false,
      title: "",
      list: [],
      queryParams: {
        title: undefined,
      },
      typeForm: {
        id: undefined,
        title: undefined,
        displayorder: 0,
        description: undefined,
        status: "1",
      },
      rules: {
        title: [
          { required: true, message: "题库名称不能为空", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    // 检索
    handleQuery() {
      this.getList();
    },
    // 重置
    resetQuery() {
      this.resetForm("queryParams");
      this.getList();
    },

    getList() {
      this.loading = true;
      queryQuestionType(this.queryParams).then((res) => {
        this.list = res.data;
        this.loading = false;
      });
    },
    // 表单重置
    reset() {
      this.resetForm("typeForm");
      this.typeForm = {
        id: undefined,
        title: undefined,
        displayorder: 0,
        description: undefined,
        status: "1",
      };
    },
    handleAdd() {
      this.reset();
      this.title = "新增题库分类";
      this.open = true;
    },
    handleUpdate(row) {
      this.reset();
      this.title = "修改题库分类";
      const id = row.id;
      queryTypeDetails({ id }).then((res) => {
        this.typeForm = res.data;
      });
      this.open = true;
    },
    handleDelete(row) {
      this.$confirm('是否确认删除名称为"' + row.title + '"的数据项？', "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        const questionTypeId = row.id;
        deleteQuestionTypeById({ questionTypeId }).then((res) => {
          this.$message({
            message: "删除成功",
            type: "success",
          });
          this.getList();
        });
      });
    },
    submitForm() {
      this.$refs["typeForm"].validate((valid) => {
        if (valid) {
          const form = this.typeForm;
          if (form.id === undefined) {
            insertQuestionType(form).then((res) => {
              this.$message({
                message: "添加成功",
                type: "success",
              });
              this.open = false;
              this.getList();
            });
          } else {
            updateQuestionTypeById(form).then((res) => {
              this.$message({
                message: "修改成功",
                type: "success",
              });
              this.open = false;
              this.getList();
            });
          }
        }
      });
    },
    cancel() {
      this.open = false;
    },
  },
};
</script>

<style scoped>
.app-container {
  padding: 20px;
}
.mb8 {
  margin: 0 -5px 8px;
}
</style>